class Controls{
    constructor(loader){
        this.controls=loader.controls()
        
        this.controlsDefault()
        // console.log(this.loader);
    }
    controlsDefault(){
        // this.controls.autoRotate=true
        // console.log('default view')
        this.controls.autoRotateSpeed=.1
        this.controls.enableDamping=true
        this.controls.enablePan=false
        this.controls.maxPolarAngle=2.944
        this.controls.minPolarAngle=1.0472
        this.controls.minDistance=.1
        this.controls.maxDistance=.5
        this.controls.target.set(0,0,0)
        this.controls.rotateSpeed=.35
    }
    controlsEnt(){
        // console.log('entrance view')
        this.controls.maxPolarAngle=2.39253
        this.controls.minPolarAngle=1.526
        this.controls.maxAzimuthAngle=4.10152
        this.controls.minAzimuthAngle=2.0944
        this.controls.minDistance=0.01
        this.controls.maxDistance=1
        this.controls.target.set(0,0,-.1)
        this.controls.autoRotate=false
    }
    controlsHall(){
        // console.log('entrance view')
        this.controls.maxPolarAngle=2.39253
        this.controls.minPolarAngle=1.526
        this.controls.maxAzimuthAngle=3.49066
        this.controls.minAzimuthAngle=2.79253
        this.controls.minDistance=0.01
        this.controls.maxDistance=1
        this.controls.target.set(0,0,-.1)
        this.controls.autoRotate=false
    }
    disbleControls(){
        this.controls.enabled=false
        this.controls.autoRotate=false
        this.controls.dispose()
    }
    tick(){
        this.controls.update()
    }
}
export{Controls}