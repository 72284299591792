import { Group, Scene } from "three"

function createScene(loader) {
    const scene=new Scene()
    // scene.background='black'
    const group=new Group()
    group.name='model'
    scene.add(group)

    // console.log(scene.children)
    return scene
}
export{createScene}