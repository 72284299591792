class BoothPopUps{
    constructor(ui,refArray){
        this.refArray=refArray
        this.ui=ui
        this.body=document.querySelector('body')

        if(this.refArray){
            // console.log(this.refArray.btnBoothUIIcons[0].btns[0].btnText)
            /** *
            this.ui.createElement('id','div','popup_container',this.body,'popup')
            const popup=document.querySelector('#popup_container')
            if(popup){
                const btnBooth_0=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[0].btnId}`)

                console.log(this.refArray.btnBoothUIIcons[0].btns[0].btnId,btnBooth_0)
            }
            /** *
            // popup container
            this.ui.createElement('id','div','popup_container',this.body,'popup')
            const popup=document.querySelector('#popup_container')

            // popup title container
            this.ui.createElement('id','div','popup_title',popup,'popup_title')
            const popupTitleContainer=document.querySelector('#popup_title')

            //popup title
            this.ui.createElement('id','h1','popup_title_text',popupTitleContainer,'popup_title_text')
            const popupTitleText=document.querySelector('#popup_title_text')
            popupTitleText.innerText=`${this.refArray.btnBoothUIIcons[0].btns[0].btnText}`

            //popup underline
            this.ui.createElement('id','div','popup_title_Line',popupTitleContainer,'popup_title_Line')
            const popupTitleLine=document.querySelector('#popup_title_Line')

            //popup close btn
            const btnPopupCLose=this.refArray.btnBoothUIIcons[0].btns[0].btnPopupClose
            this.ui.btnListWithImgs(btnPopupCLose,popup)

            //popup content
            this.ui.createElement('id','div','popup_Content',popup,'popup_Content')
            const popupContent=document.querySelector('#popup_Content')
            const contentCount=this.refArray.btnBoothUIIcons[0].btns[0].popupConent
            contentCount.forEach(element => {
                const content=document.createElement('img','content')
                popupContent.append(content)
                content.setAttribute('src',element)
            })
            const btnClosePopup=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[0].btnPopupClose[0].btnId}`)
            btnClosePopup.addEventListener('click',()=>{
                popup.remove()
                btnClosePopup.remove()
            })

            // console.log(this.refArray.btnBoothUIIcons[0].btns[0].popupConent,popupContent)


            /**
             * Generate booth popups
             *
            // console.log(this.refArray.btnBoothUIIcons[0].btns[0].btnId)
            const btnBooth_0=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[0].btnId}`)
            // this.generatePopup(btnBooth_0,this.refArray.btnBoothUIIcons[0])

            // const btnBooth_1=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[1].btnId}`)
            // this.generatePopup(btnBooth_1,this.refArray.btnBoothUIIcons[1])

            // const btnBooth_2=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[2].btnId}`)
            // this.generatePopup(btnBooth_2,this.refArray.btnBoothUIIcons[2])

            // const btnBooth_3=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[3].btnId}`)
            // this.generatePopup(btnBooth_3,this.refArray.btnBoothUIIcons[3])

            // const btnBooth_4=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[4].btnId}`)
            // this.generatePopup(btnBooth_4,this.refArray.btnBoothUIIcons[4])

            // const btnBooth_5=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[5].btnId}`)
            // this.generatePopup(btnBooth_5,this.refArray.btnBoothUIIcons[5])

            /** */
            // console.log(this.refArray.btnBoothUIIcons[0].btns[0].btnId,btnBooth_0)
        }
        // console.log(btnBooth_0)
    }
    generatePopup(btn,objPopup){
        btn.addEventListener('click',()=>{
            // popup container
            this.ui.createElement('id','div','popup_container',this.body,'popup')
            const popup=document.querySelector('#popup_container')

            // popup title container
            this.ui.createElement('id','div','popup_title',popup,'popup_title')
            const popupTitleContainer=document.querySelector('#popup_title')

            //popup title
            this.ui.createElement('id','h1','popup_title_text',popupTitleContainer,'popup_title_text')
            const popupTitleText=document.querySelector('#popup_title_text')
            popupTitleText.innerText=`${objPopup.btns[0].btnText}`

            //popup underline
            this.ui.createElement('id','div','popup_title_Line',popupTitleContainer,'popup_title_Line')
            const popupTitleLine=document.querySelector('#popup_title_Line')

            //popup close btn
            const btnPopupCLose=objPopup.btns[0].btnPopupClose
            this.ui.btnListWithImgs(btnPopupCLose,popup)

            //popup content
            this.ui.createElement('id','div','popup_Content',popup,'popup_Content')
            const popupContent=document.querySelector('#popup_Content')
            const contentCount=objPopup.btns[0].popupConent
            contentCount.forEach(element => {
                const content=document.createElement('img','content')
                popupContent.append(content)
                content.setAttribute('src',element)
            })
            const btnClosePopup=document.querySelector(`#${objPopup.btns[0].btnPopupClose[0].btnId}`)
            btnClosePopup.addEventListener('click',()=>{
                popup.remove()
                btnClosePopup.remove()
            })

        })
    }
}
export{BoothPopUps}