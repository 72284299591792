import { Vector2,Vector3,Raycaster} from "three"
import { Object } from "../components/Object.js"
import { GUI } from "../systems/GUI.js"

class RaycastingManager{
    constructor(refArray,camera,container,scene){
        this.refArray=refArray
        this.camera=camera
        this.container=container
        this.objs=new Object(scene)
        this.gui=new GUI()
        this.points=[
            {
                position:new Vector3(this.refArray.btnBoothUIIcons[0].position[0],this.refArray.btnBoothUIIcons[0].position[1],this.refArray.btnBoothUIIcons[0].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnBoothUIIcons[1].position[0],this.refArray.btnBoothUIIcons[0].position[1],this.refArray.btnBoothUIIcons[0].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnBoothUIIcons[1].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnBoothUIIcons[2].position[0],this.refArray.btnBoothUIIcons[0].position[1],this.refArray.btnBoothUIIcons[0].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnBoothUIIcons[2].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnBoothUIIcons[3].position[0],this.refArray.btnBoothUIIcons[0].position[1],this.refArray.btnBoothUIIcons[0].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnBoothUIIcons[3].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnBoothUIIcons[4].position[0],this.refArray.btnBoothUIIcons[0].position[1],this.refArray.btnBoothUIIcons[0].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnBoothUIIcons[4].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnBoothUIIcons[5].position[0],this.refArray.btnBoothUIIcons[0].position[1],this.refArray.btnBoothUIIcons[0].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnBoothUIIcons[5].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnUIIcons[0].position[0],this.refArray.btnUIIcons[0].position[1],this.refArray.btnUIIcons[0].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnUIIcons[0].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnUIIcons[1].position[0],this.refArray.btnUIIcons[0].position[1],this.refArray.btnUIIcons[1].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnUIIcons[1].btnId}`)
            },
            {
                position:new Vector3(this.refArray.btnUIIcons[2].position[0],this.refArray.btnUIIcons[2].position[1],this.refArray.btnUIIcons[2].position[2]),
                elementDom:document.querySelector(`#${this.refArray.btnUIIcons[2].btnId}`)
            }

        ]
        this.cursor=new Vector2()
        this.raycaster=new Raycaster()

        // console.log(document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btnId}`))

        /** 
         * point of interest
        */
        const pointOfInterest0=this.objs.sphereObj('POI',.25,32.32)
        pointOfInterest0.position.set(-0.0921,-.222,4.75)
        pointOfInterest0.visible=false

        this.pointsOfInterest=[pointOfInterest0]

        window.addEventListener('mousemove',(event)=>{
            this.cursor.x=(event.clientX/this.container.clientWidth)*2-1
            this.cursor.y=-(event.clientY/this.container.clientHeight*2-1)
            // console.log('mouse move',this.cursor.y);
        })

        // console.log(this.points)

        /**
         * gui
         *
        // console.log(this.gui)
        this.gui.positionGUI(pointOfInterest0,'poi')
        this.gui.rotationGUI(pointOfInterest0,'model')
        // console.log(this.raycaster,this.pointsOfInterest,this.intersects)
        /** */
    }
    pointsNotOccluded(){
        this.points.forEach(point => {
            const screenPosition=point.position.clone()
            screenPosition.project(this.camera)

            const translateX=screenPosition.x*this.container.clientWidth*0.5
            const translateY=-screenPosition.y*this.container.clientHeight*0.5
            point.elementDom.style.transform=`translateX(${translateX}px) translateY(${translateY}px)`

            // console.log(point.elementDom.style.transfrom)
        })
        this.raycaster.setFromCamera(this.cursor,this.camera)

        const intersects=this.raycaster.intersectObjects(this.pointsOfInterest)

        this.pointsOfInterest.forEach(point => {
            // console.log(point.material.color)
            point.material.color.set(0xff0000)
        })
        intersects.forEach(intersect => {
            // console.log(intersect)
            intersect.object.material.color.set(0x0000ff)
            // intersect.object.material.transparen
            // intersect.object.material.alpha=0
        })

        // console.log(this.intersect)
    }
    pointsOccluded(){
        this.points.forEach(point => {
            const screenPosition=point.position.clone()
            screenPosition.project(this.camera)

            const translateX=screenPosition.x*this.container.clientWidth*0.5
            const translateY=-screenPosition.y*this.container.clientHeight*0.5
            point.elementDom.style.transform=`translateX(${translateX}px) translateY(${translateY}px)`

            // console.log(point.elementDom.style.transfrom)
        })
    }
    tick(){
        this.pointsNotOccluded()
    }
}
export{RaycastingManager}