import { Object } from "../components/Object.js"
import { Textures } from "../systems/Textures.js"
import { GUI } from "../systems/GUI.js"
import { BackSide, MeshBasicMaterial, Vector3 } from "three"

class GltfManager{
    constructor(ui,loader,scene,camera,refArray,controls){
        this.arMode=false
        this.ui=ui
        this.controls=controls
        this.camera=camera
        this.loader=loader
        this.scene=scene
        this.refArray=refArray
        this.textureManager=new Textures(loader,scene)
        this.objs=new Object(scene)
        this.modelObj=this.scene.getObjectByName('model')
        this.modelProperties=this.refArray.models

        this.loadModels()

        // console.log(this.scene)
    }

    async loadModels(){
        /** */
        //camera 360 code
        this.camera.position.set(0,0,-0.5)

        /**
         * camera 360 code
         */
        this.controls.controlsHall()

        /**
         * obj 360 code
         */
        this.obj360=this.objs.sphereObj('obj360',5,32,32)
        this.obj360.name=this.refArray.obj360[0].name
        this.obj360.rotation.x=3.14
        this.obj360.rotation.y=1.57
        this.obj360.material=new MeshBasicMaterial({side:BackSide})
        
        const image360_1=this.refArray.maps[1]
        const image360_2=this.refArray.maps[2]

        let imgMap=this.textureManager.textureLoad(image360_1)
        imgMap.name=image360_1
        this.obj360.material.map=imgMap
        
        // this.obj360.visible=false

        /**
         * help popup
         */

        /**
         * BAck btn hide
         */
         const btnBack=document.querySelector(`#${this.refArray.btnBack[0].btnId}`)
         btnBack.style.display='none'

        /**
         * btn enter
         */
        const btnEnter=document.querySelector(`#${this.refArray.btnUIIcons[0].btnId}`)
        btnEnter.addEventListener('click',()=>{
            // console.log(this.obj360.material.map.name===this.refArray.maps[1])

            /** */
            if(this.obj360.material.map.name===image360_1){
                // console.log('scene 1')
                imgMap=this.textureManager.textureLoad(image360_2)
                imgMap.name=image360_2
                this.obj360.material.map=imgMap

                //turn off entrance icon
                document.querySelector(`#${this.refArray.btnUIIcons[0].btnId}`).style.display='none'

                //activate spiner
                document.querySelector(`#spinerContainer`).style.display='inline'
                //turn on arrow btns
                this.showHallBtns()
                this.controls.controlsEnt()
                btnBack.style.display='inline'


                // this.controls.controlsDefault()
            }else{
                console.log('scene 2')
                imgMap=this.textureManager.textureLoad(image360_1)
                imgMap.name=image360_1
                this.obj360.material.map=imgMap
                // this.controls.controlsDefault()
            }
            /** */
        })
        
        
        // console.log(btnBack)
        btnBack.addEventListener('click',()=>{
            // console.log(this.obj360.material.map.name)
            if(this.obj360.material.map.name===`${image360_2}`){
                imgMap=this.textureManager.textureLoad(image360_1)
                imgMap.name=image360_1
                this.obj360.material.map=imgMap
                this.hideHallBtns()
                document.querySelector(`#${this.refArray.btnUIIcons[0].btnId}`).style.display='inline'
                btnBack.style.display='none'
            }
        })

        /** */
    }

    showHallBtns(){
        // document.querySelector(`#${this.refArray.btnUIIcons[1].btnId}`).style.display='inline'
        // document.querySelector(`#${this.refArray.btnUIIcons[2].btnId}`).style.display='inline'
        
        this.refArray.btnBoothUIIcons.forEach(element => {
            document.querySelector(`#${element.btnId}`).style.display='inline'
        });
    }

    hideHallBtns(){
        // document.querySelector(`#${this.refArray.btnUIIcons[1].btnId}`).style.display='none'
        // document.querySelector(`#${this.refArray.btnUIIcons[2].btnId}`).style.display='none'
        this.refArray.btnBoothUIIcons.forEach(element => {
            document.querySelector(`#${element.btnId}`).style.display='none'
        });
    }

    btnRmSnapAddEventListener(rmObj){
        const btn=document.querySelector(`#${rmObj.name}`)
        // console.log(rmObj)
        btn.addEventListener('click',()=>{
            // console.log(btn,objPosValuesArray)
            this.modelDefaultTransform(this.modelObj,rmObj.pos)
            this.controls.controlsRooms()
            this.camera.position.set(0,0,0)
            this.btnClose.style.display='inline'
        })
    }

    btn360AddEventListener(obj360Prop,){
        // console.log(obj360Prop.name)
        const btn=document.querySelector(`#${obj360Prop.name}`)
        btn.addEventListener('click',()=>{
            this.obj360.material.map=this.textureManager.textureLoad(obj360Prop.map)
            // console.log(btn,objToHide,objToShow)
            if(this.modelObj.visible&&!this.obj360.visible){
                this.modelObj.visible=false
                this.obj360.visible=true
                // console.log(objToHide,objToShow)
            }
            this.controls.controlsRooms()
            this.camera.position.set(0.5,0,0.5)
            this.btnClose.style.display='inline'
        })
    }

    closeBtn(){
        this.controls.controlsDefault()
        this.objPosition(this.camera,this.refArray.camPosElevation[5].pos)
        this.modelDefaultTransform(this.modelObj,this.refArray.models.position,this.refArray.models.rotation)
        this.btnClose.style.display='none'
        if(!this.modelObj.visible&&this.obj360.visible){
            this.modelObj.visible=true
            this.obj360.visible=false
        }
    }

    modelDefaultTransform(obj,objPosValuesArray){
        obj.position.set(objPosValuesArray[0],objPosValuesArray[1],objPosValuesArray[2])
        obj.rotation.y=objPosValuesArray[3]
    }

    objPosition(model,objPosValuesArray){
        model.position.set(objPosValuesArray[0],objPosValuesArray[1],objPosValuesArray[2])
    }

    objRotation(model,radian){
        model.rotation.y=radian
    }
    
    objARTransform(model,x,y,z,radian){
        console.log(model,x,y,z,radian)
        this.objPosition(model,x,y,z)
        this.objRotation(model,radian)
    }

    levelsToggle(objlName){
        const model=this.scene.getObjectByName(objlName)
        if(model){
            if(model.visible){
                model.visible=false
                // console.log(model.visible)
            }else{
                model.visible=true
                // console.log(model.visible)
            }
        }
    }
}
export {GltfManager}