import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import{ PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js'
import { LoadingManager, TextureLoader } from "three"
// import {} from '../thre/e.js/loaders'

class Loaders{
    constructor(camera,renderer,refArray){
        this.refArray=refArray.progressCounter
        this.renderer=renderer
        this.camera=camera
        this.dracoLoader=new DRACOLoader(this.loaderManager)
        this.dracoLoader.setDecoderPath("./draco/")
        
        this.loaderManager=new LoadingManager(
            ()=>{
                // this.disableProgresser()
                setTimeout(this.disableProgresser(),10000)
                // console.log(itemsLoad);
                // if(){
                //     setTimeout(this.disableProgresserHaLL(),10000)
                // }
                // console.log('loaded',enterBtn)
            },
            (url,itemsLoad,itemsTotal)=>{
                const progress=itemsLoad/itemsTotal

                // document.querySelector(this.refArray[2]).innerText=progress
                if(url==='./textures/360_hall.jpg'){
                        // console.log('hall downloading')
                        setTimeout(this.disableProgresserHaLL(),10000)
                }
                // console.log('loadeding',progress,url)
            })
        
        // console.log(document.querySelector(this.refArray[2]))
    }
    gltfLoader(){
        const gltfLoader=new GLTFLoader(this.loaderManager)
        gltfLoader.setDRACOLoader(this.dracoLoader)
        return gltfLoader
    }
    rgbeLoader(){
        const rgbeLoader=new RGBELoader(this.loaderManager)
        return rgbeLoader
    }
    textureLoader(){
        const textureLoader=new TextureLoader(this.loaderManager)
        return textureLoader
    }
    loadmanager(){
        const loadingManager=this.loaderManager
        return loadingManager
    }
    controls(){
        const controls=new OrbitControls(this.camera,this.renderer.domElement)
        return controls
    }
    
    disableProgresser(){
        document.querySelector('#landing_Page').style.display='none'
        document.querySelector('#spinerContainer').style.display='none'
    }
    disableProgresserHaLL(){
        document.querySelector('#spinerContainer').style.display='none'
    }
}
export{Loaders}