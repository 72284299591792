class ExtFilesArray{
    constructor(){
        //glb file names, models to be loaded
        this.landPageElements={
            img:'SHIFT3D_BG.jpg',
            text:`WELCOME TO 
            DIGITAL SHIFT BW 
            -THE VIRTUAL EXPERIENCE-
            
            Click below for our Virtual Experience...`
        }
        this.WelcomePageElements={
            btnImg:'digital_shift_bw_btn_off.png',
            text:`WELCOME TO 
            DIGITAL SHIFT BW 
            -THE VIRTUAL EXPERIENCE-
            
            Click below for our Virtual Experience...`
        }

        this.topBar={
            topBarLogo:'BTC-LOGO_nav.png',
            topBackgrdImg:'btc_greenbar.gif',
            topBarShadow:'shadow_bar.png',
            topBTCLogo:'live_connected_btc_logo.png'
        }

        this.btnSwap=[
            {
                btnId:'btnWelomeImg',
                btnImgs:['digital_shift_bw_btn_off.png','digital_shift_bw_btn_ov.png']
            },
            {
                btnId:'btnBackImg',
                btnImgs:['back_btn_off.png','back_btn_ov.png']
            }
        ]

        this.companyBtns=[
            {
                btnId:'btnCompanyImg0',
                btnImgs:['huawei_btn_off.png','huawei_btn_ov.png'],
                class:'btnCompany',
                elementTag:'li',
                url:'https://www.huawei.com'
            },
            {
                btnId:'btnCompanyImg1',
                btnImgs:['mitel_btn_off.png','mitel_btn_ov.png'],
                class:'btnCompany',
                elementTag:'li',
                url:'https://www.mitel.com/'
            },
            {
                btnId:'btnCompanyImg2',
                btnImgs:['avm_btn_off.png','avm_btn_ov.png'],
                class:'btnCompany',
                elementTag:'li',
                url:'https://en.avm.de/'
            },
            {
                btnId:'btnCompanyImg3',
                btnImgs:['huawei_logo_btn_off.png','huawei_logo_btn_ov.png'],
                class:'btnCompany',
                elementTag:'li',
                url:'https://www.bofinet.co.bw/'
            },
            {
                btnId:'btnCompanyImg4',
                btnImgs:['subex_logo_btn_off.png','subex_logo_btn_ov.png'],
                class:'btnCompany',
                elementTag:'li',
                url:'https://www.subex.com/'
            }
        ]

        this.btnBack=[
            {
                btnId:'btnBack',
                btnImgs:['back_btn_off.png','back_btn_ov.png'],
                class:'btnBack',
                elementTag:'div'
            },
            {
                btnId:'btc',
                btnImgs:['btc_web_link_btn_off.png','btc_web_link_btn_ov.png'],
                class:'btnBack',
                elementTag:'div'
            }
        ]

        this.btnBoothBack=[
            {
                btnId:'btnBoothBack',
                btnImgs:['back_btn_off.png','back_btn_ov.png'],
                class:'btnBoothBack',
                elementTag:'div'
            }
        ]

        this.btnsHelp=[
            /** */
            {
                btnId:'btnMap',btnImgs:['map_btn_off.png','map_btn_ov.png'],class:'btn_help',elementTag:'li',btnText:'help',btnPopupClose:[
                    {
                        btnId:'btnClosePopup',
                        btnImgs:['close_btn_off.png','close_btn_ov.png'],
                        class:'btn_Close_Popup',
                        elementTag:'div',
                        display:'inline'
                    }
                ],popupConent:['./textures/help.png']
            }
            /** *
            {
                btnId:'btnMap',
                btnImgs:['map_btn_off.png','map_btn_ov.png'],
                class:'btn_help',
                elementTag:'li'
            }
            /** */
        ]

        this.btnUIIcons=[
            {
                btnId:'btnEnter',
                btnImgs:['door_btn_off.png','door_btn_ov.png'],
                class:'btn_Enter ui_btns point point_0 ',
                elementTag:'div',
                position:[0.25,-.45,4.75],
                display:'inline'
            },
            {
                btnId:'btnArrowLeft',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns point point_0 ',
                elementTag:'div',
                position:[2,-0.35,4.75],
                display:'none'
            },
            {
                btnId:'btnArrowRight',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns point point_0 ',
                elementTag:'div',
                position:[-2,-0.35,4.75],
                display:'none'
            }
        ]
        this.btnBoothUIIcons=[
            {
                btnId:'Bofinet',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns scale point point_0 ',
                elementTag:'div',
                position:[-4.25,-.5,4.5],
                display:'none',
                boothIamage:'./textures/BOOTHBOFINET.jpg',
                boothName:'Bofinet_Booth',
                contentPath:'./textures/Bofinet/',
                btns:
                    [
                        {
                            btnId:'businesscard',btnImgs:['bcard_icon_btn_off.png','bcard_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'businesscard',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Bofinet/businesscard.png']
                        },
                        {
                            btnId:'bio',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'bio',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Bofinet/bio.png','./textures/Bofinet/bio2.png']
                        },
                        {
                            btnId:'documents',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'documents',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:[
                                {
                                    btnID:'pdf001',
                                    id:'imgPDF001',
                                    name:'BofiNet Brochure',
                                    path:'./textures/Bofinet/BofiNet Brochure.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                },
                                {
                                    btnID:'pdf002',
                                    id:'imgPDF002',
                                    name:'Bofinet Infrastructure Connecting Maps',
                                    path:'./textures/Bofinet/Bofinet Infrastructure Connecting Maps.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                }
                            ],pdfPresent:true
                        },
                        {
                            btnId:'images',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'images',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Bofinet/image001.png','./textures/Bofinet/image002.png','./textures/Bofinet/image003.png','./textures/Bofinet/image004.png','./textures/Bofinet/image005.png','./textures/Bofinet/image006.png','./textures/Bofinet/image007.png']
                        },
                        {
                            btnId:'video',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'video',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:{videoName:'bofinet_video.',videoPath:'./textures/Bofinet/',type:'mp4',playBtn:['play_btn_off.png','play_btn_ov.png'],pauseBtn:['pause_btn_off.png','pause_btn_ov.png']},videoPresent:true
                        },
                        {
                            btnId:'chat',btnImgs:['chat_icon_btn_off.png','chat_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'',spineIcon:'./images/rotating_btn_element.png',chatURL:'https://direct.lc.chat/14527071/'
                        }
                    ]
            },
            {
                btnId:'Btc',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns scale point point_0 ',
                elementTag:'div',
                position:[1.75,-.5,4.5],
                display:'none',
                boothIamage:'./textures/BOOTHBTC.jpg',
                boothName:'Btc_Booth',
                contentPath:'./textures/Btc/',
                btns:
                    [
                        {
                            btnId:'businesscard',btnImgs:['bcard_icon_btn_off.png','bcard_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'businesscard',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Btc/businesscard.png']
                        },
                        {
                            btnId:'bio',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'bio',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Btc/bio.png','./textures/Btc/bio2.png']
                        },
                        {
                            btnId:'documents',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'documents',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:[
                                {
                                    btnID:'pdf001',
                                    id:'imgPDF001',
                                    name:'Enterprise Catalogue 2022',
                                    path:'./textures/Btc/Enterprise Catalogue 2022.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                }
                            ],pdfPresent:true
                        },
                        {
                            btnId:'images',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'images',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Btc/image001.png','./textures/Btc/image002.png','./textures/Btc/image003.png','./textures/Btc/image004.png','./textures/Btc/image005.png','./textures/Btc/image007.png']
                        },
                        {
                            btnId:'video',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'video',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:{videoName:'SPACECOM.',videoPath:'./textures/Btc/',type:'mp4',playBtn:['play_btn_off.png','play_btn_ov.png'],pauseBtn:['pause_btn_off.png','pause_btn_ov.png']},videoPresent:false
                        },
                        {
                            btnId:'chat',btnImgs:['chat_icon_btn_off.png','chat_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'',spineIcon:'./images/rotating_btn_element.png',chatURL:'https://direct.lc.chat/14527071/'
                        }
                    ]
            },
            {
                btnId:'huawei',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns scale point point_0 ',
                elementTag:'div',
                position:[-1,-.5,4.5],
                display:'none',
                boothIamage:'./textures/BOOTHHUAWEI.jpg',
                boothName:'huawei_Booth',
                contentPath:'./textures/Huawei/',
                btns:
                    [
                        {
                            btnId:'businesscard',btnImgs:['bcard_icon_btn_off.png','bcard_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'businesscard',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Huawei/businesscard.png']
                        },
                        {
                            btnId:'bio',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'bio',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Huawei/bio.png','./textures/Huawei/bio2.png']
                        },
                        {
                            btnId:'documents',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'documents',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:[
                                {
                                    btnID:'pdf001',
                                    id:'imgPDF001',
                                    name:'Brochure - Mitel MiContact Center Business',
                                    path:'./textures/Huawei/Brochure - Mitel MiContact Center Business.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                }
                            ],pdfPresent:false
                        },
                        {
                            btnId:'images',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'images',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Huawei/image001.png','./textures/Huawei/image002.png','./textures/Huawei/image003.png','./textures/Huawei/image004.png','./textures/Huawei/image005.png','./textures/Huawei/image006.png','./textures/Huawei/image007.png']
                        },
                        {
                            btnId:'video',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'video',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:{videoName:'SPACECOM.',videoPath:'./textures/Huawei/',type:'mp4',playBtn:['play_btn_off.png','play_btn_ov.png'],pauseBtn:['pause_btn_off.png','pause_btn_ov.png']},videoPresent:false
                        },
                        {
                            btnId:'chat',btnImgs:['chat_icon_btn_off.png','chat_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'',spineIcon:'./images/rotating_btn_element.png',chatURL:'https://direct.lc.chat/14542134/'
                        }
                    ]
            },
            {
                btnId:'subex',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns scale point point_0 ',
                elementTag:'div',
                position:[4.25,-.5,5],
                display:'none',
                boothIamage:'./textures/BOOTHSUBEX.jpg',
                boothName:'subex_Booth',
                contentPath:'./textures/Subex/',
                btns:
                    [
                        {
                            btnId:'businesscard',btnImgs:['bcard_icon_btn_off.png','bcard_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'businesscard',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Subex/businesscard.png','./textures/Subex/businesscard2.png']
                        },
                        {
                            btnId:'bio',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'bio',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Subex/bio.png','./textures/Subex/bio2.png']
                        },
                        {
                            btnId:'documents',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'documents',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:[
                                {
                                    btnID:'pdf001',
                                    id:'imgPDF001',
                                    name:'HyperSense Platform',
                                    path:'./textures/Subex/HyperSense Platform.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                },
                                {
                                    btnID:'pdf002',
                                    id:'imgPDF002',
                                    name:'Subex Corporate Brochure',
                                    path:'./textures/Subex/Subex Corporate Brochure.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                }
                            ],pdfPresent:true
                        },
                        {
                            btnId:'images',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'images',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Subex/image001.png','./textures/Subex/image002.png','./textures/Subex/image003.png','./textures/Subex/image004.png','./textures/Subex/image007.png']
                        },
                        {
                            btnId:'video',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'video',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:{videoName:'SPACECOM.',videoPath:'./textures/Subex/',type:'mp4',playBtn:['play_btn_off.png','play_btn_ov.png'],pauseBtn:['pause_btn_off.png','pause_btn_ov.png']},videoPresent:false
                        },
                        {
                            btnId:'chat',btnImgs:['chat_icon_btn_off.png','chat_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'',spineIcon:'./images/rotating_btn_element.png',chatURL:'https://direct.lc.chat/12938724/'
                        }
                    ]
            },
            {
                btnId:'avm',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns scale point point_0 ',
                elementTag:'div',
                position:[-10,-.5,5],
                display:'none',
                boothIamage:'./textures/BOOTHAVM.jpg',
                boothName:'avm_Booth',
                contentPath:'./textures/Avm/',
                btns:
                    [
                        {
                            btnId:'businesscard',btnImgs:['bcard_icon_btn_off.png','bcard_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'businesscard',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Avm/businesscard.png']
                        },
                        {
                            btnId:'bio',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'bio',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Avm/bio.png','./textures/Avm/bio2.png']
                        },
                        {
                            btnId:'documents',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'documents',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:[
                                {
                                    btnID:'pdf001',
                                    id:'imgPDF001',
                                    name:'Brochure - Mitel MiContact Center Business',
                                    path:'./textures/Avm/Brochure - Mitel MiContact Center Business.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                }
                            ],pdfPresent:false
                        },
                        {
                            btnId:'images',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'images',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:[
                            ],popupConent:['./textures/Avm/image001.png','./textures/Avm/image002.png','./textures/Avm/image003.png','./textures/Avm/image004.png','./textures/Avm/image005.png','./textures/Avm/image006.png','./textures/Avm/image007.png']
                        },
                        {
                            btnId:'video',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'video',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:{videoName:'SPACECOM.',videoPath:'./textures/Avm/',type:'mp4',playBtn:['play_btn_off.png','play_btn_ov.png'],pauseBtn:['pause_btn_off.png','pause_btn_ov.png']},videoPresent:false
                        },
                        {
                            btnId:'chat',btnImgs:['chat_icon_btn_off.png','chat_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'',spineIcon:'./images/rotating_btn_element.png',chatURL:'https://direct.lc.chat/14542104/'
                        }
                    ]
            },
            {
                btnId:'mitel',
                btnImgs:['nav_arrow_off.png','nav_arrow_ov.png'],
                class:'btn_Enter ui_btns scale point point_0 ',
                elementTag:'div',
                position:[12,-1.25,4],
                display:'none',
                boothIamage:'./textures/BOOTHMITEL.jpg',
                boothName:'mitel_Booth',
                contentPath:'./textures/Mitel/',
                btns:
                    [
                        {
                            btnId:'businesscard',btnImgs:['bcard_icon_btn_off.png','bcard_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'businesscard',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Mitel/businesscard.png']
                        },
                        {
                            btnId:'bio',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'bio',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Mitel/bio.png','./textures/Mitel/bio2.png']
                        },
                        {
                            btnId:'documents',btnImgs:['bio_icon_btn_off.png','bio_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'documents',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:[
                                {
                                    btnID:'pdf001',
                                    id:'imgPDF001',
                                    name:'Mitel MiCollab',
                                    path:'./textures/Mitel/Mitel MiCollab.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                },
                                {
                                    btnID:'pdf002',
                                    id:'imgPDF002',
                                    name:'Mitel MiContact Center Business.pdf',
                                    path:'./textures/Mitel/Mitel MiContact Center Business.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                },
                                {
                                    btnID:'pdf003',
                                    id:'imgPDF003',
                                    name:'Mitel MiVoice Business',
                                    path:'./textures/Mitel/Mitel MiVoice Business.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                },
                                {
                                    btnID:'pdf004',
                                    id:'imgPDF004',
                                    name:'Mitel MiVoice Office 400',
                                    path:'./textures/Mitel/Mitel MiVoice Office 400.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                },
                                {
                                    btnID:'pdf005',
                                    id:'imgPDF005',
                                    name:'Mitel MX-One',
                                    path:'./textures/Mitel/Mitel MX-One.pdf',
                                    btnImgs:['document_download_btn_off.png','document_download_btn_ov.png']
                                }
                            ],pdfPresent:true
                        },
                        {
                            btnId:'images',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'images',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:['./textures/Mitel/image001.png','./textures/Mitel/image002.png','./textures/Mitel/image003.png','./textures/Mitel/image004.png','./textures/Mitel/image005.png','./textures/Mitel/image006.png','./textures/Mitel/image007.png']
                        },
                        {
                            btnId:'video',btnImgs:['images_icon_btn_off.png','images_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'video',spineIcon:'./images/rotating_btn_element.png',btnPopupClose:[
                                {
                                    btnId:'btnClosePopup',
                                    btnImgs:['close_btn_off.png','close_btn_ov.png'],
                                    class:'btn_Close_Popup',
                                    elementTag:'div',
                                    display:'inline'
                                }
                            ],popupConent:{videoName:'Mitel - MS Teams Integration.',videoPath:'./textures/Mitel/',type:'mp4',playBtn:['play_btn_off.png','play_btn_ov.png'],pauseBtn:['pause_btn_off.png','pause_btn_ov.png']},videoPresent:true
                        },
                        {
                            btnId:'chat',btnImgs:['chat_icon_btn_off.png','chat_icon_btn_ov.png'],class:'btnBooth',elementTag:'div',btnText:'',spineIcon:'./images/rotating_btn_element.png',chatURL:'https://direct.lc.chat/14542161/'
                        }
                    ]
            }
        ]

        this.obj360=[
            {
                name:'model360',
                maps:['360_0','360_1','306_1','enviMap_512']
            }
        ]

        this.maps=[
            'enviMap_512','360_entrance','360_hall'
        ]

        this.progressCounter=['#progress','#loader','#progress_counter','progress_text']
        /** */
    }
}
export{ExtFilesArray}