class BoothGenerator{
    constructor(ui,refArray){
        this.refArray=refArray
        this.ui=ui
        this.body=document.querySelector('body')

        if(this.refArray){
            // console.log(this.refArray.btnBoothUIIcons)

            /**
             * generate url links
             */
            const btnHuawei=document.querySelector(`#${this.refArray.companyBtns[0].btnId}`)
            this.ui.openURL(btnHuawei,this.refArray.companyBtns[0].url)

            const btnMitel=document.querySelector(`#${this.refArray.companyBtns[1].btnId}`)
            this.ui.openURL(btnMitel,this.refArray.companyBtns[1].url)

            const btnAVM=document.querySelector(`#${this.refArray.companyBtns[2].btnId}`)
            this.ui.openURL(btnAVM,this.refArray.companyBtns[2].url)

            const btnBofinet=document.querySelector(`#${this.refArray.companyBtns[3].btnId}`)
            this.ui.openURL(btnBofinet,this.refArray.companyBtns[3].url)

            const btnSubex=document.querySelector(`#${this.refArray.companyBtns[4].btnId}`)
            this.ui.openURL(btnSubex,this.refArray.companyBtns[4].url)

            const btnBTC=document.querySelector(`#btc`)
            this.ui.openURL(btnBTC,'https://btc.bw/')

            // console.log(btnBTC)

            //pdf popups
            //booth activation btns
            // console.log('hall btn generator',this.refArray.btnBoothUIIcons)
            const btnBoothNav=this.refArray.btnBoothUIIcons
            this.ui.createBtnBoothNavigationText(btnBoothNav,this.body)

            /**
             * Generate help popup
             */
             const btnHelpPopup=document.querySelector(`#${this.refArray.btnsHelp[0].btnId}`)
             this.generateHelpPopup(btnHelpPopup,this.refArray.btnsHelp[0])

            /**
             * Generate booth popups
             */
            const btnBooth_0=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btnId}`)
            this.generateBooth(btnBooth_0,this.refArray.btnBoothUIIcons[0])

            const btnBooth_1=document.querySelector(`#${this.refArray.btnBoothUIIcons[1].btnId}`)
            this.generateBooth(btnBooth_1,this.refArray.btnBoothUIIcons[1])

            const btnBooth_2=document.querySelector(`#${this.refArray.btnBoothUIIcons[2].btnId}`)
            this.generateBooth(btnBooth_2,this.refArray.btnBoothUIIcons[2])

            const btnBooth_3=document.querySelector(`#${this.refArray.btnBoothUIIcons[3].btnId}`)
            this.generateBooth(btnBooth_3,this.refArray.btnBoothUIIcons[3])

            const btnBooth_4=document.querySelector(`#${this.refArray.btnBoothUIIcons[4].btnId}`)
            this.generateBooth(btnBooth_4,this.refArray.btnBoothUIIcons[4])

            const btnBooth_5=document.querySelector(`#${this.refArray.btnBoothUIIcons[5].btnId}`)
            this.generateBooth(btnBooth_5,this.refArray.btnBoothUIIcons[5])

            // console.log(this.refArray.btnBoothUIIcons[2].btnId)

            /** */
        }
    }
    generateBooth(btn,objBooth){
        // console.log('generate booth',objBooth,btn)
        btn.addEventListener('click',()=>{
            // console.log(objBooth)
            this.ui.createBooth(objBooth)

            //create back button
            const backBtnForBooth=this.refArray.btnBoothBack
            this.ui.btnListWithImgs(backBtnForBooth,this.body)

            //add back button functionality
            const btnBackBooth=document.querySelector(`#${this.refArray.btnBoothBack[0].btnId}`)
            btnBackBooth.addEventListener('click',()=>{
                // console.log('back btn',objBooth)
                const boothContainer=document.querySelector(`#${objBooth.boothName}`)
                boothContainer.remove()
                btnBoothBack.remove()
            })
            
            this.boothMasterGenerator(objBooth)
        })
    }
    boothMasterGenerator(objBooth){
        // console.log('obj?',objBooth.btns)
        const boothObj=document.querySelector(`#${objBooth.boothName}`)
        // console.log(boothObj,objBooth.boothName)
        if(boothObj.id==objBooth.boothName){
            // console.log(`booth generated`)
            this.boothBasedPopupAssignment(objBooth.btns)
        }
    }
    boothBasedPopupAssignment(objBooth){
        // console.log('btn assigning',objBooth,objBooth[0].btnId)
        //businesscard popup
        const btnBusinesCard=document.querySelector(`#${objBooth[0].btnId}`)
        this.generatePopup(btnBusinesCard,objBooth[0])

        //businesscard popup
        const btnBio=document.querySelector(`#${objBooth[1].btnId}`)
        this.generatePopup(btnBio,objBooth[1])

        //documents popup
        const btnDocument=document.querySelector(`#${objBooth[2].btnId}`)
        this.generatePopupPDF(btnDocument,objBooth[2])

        //images popup
        const btnImages=document.querySelector(`#${objBooth[3].btnId}`)
        this.generatePopup(btnImages,objBooth[3])

        //video popup
        const btnVideo=document.querySelector(`#${objBooth[4].btnId}`)
        this.generatePopupVideo(btnVideo,objBooth[4])

        //Chat popup
        const btnChat=document.querySelector(`#${objBooth[5].btnId}`)
        this.ui.openURL(btnChat,objBooth[5].chatURL)
    }
    generatePopup(btn,objPopup){
        // console.log('genral popup',objPopup)
        btn.addEventListener('click',()=>{
            
            // popup container
            this.ui.createElement('id','div','popup_container',this.body,'popup')
            const popup=document.querySelector('#popup_container')

            // popup title container
            this.ui.createElement('id','div','popup_title',popup,'popup_title')
            const popupTitleContainer=document.querySelector('#popup_title')

            //popup title
            this.ui.createElement('id','h1','popup_title_text',popupTitleContainer,'popup_title_text')
            const popupTitleText=document.querySelector('#popup_title_text')
            popupTitleText.innerText=`${objPopup.btnText}`

            //popup underline
            this.ui.createElement('id','div','popup_title_Line',popupTitleContainer,'popup_title_Line')
            const popupTitleLine=document.querySelector('#popup_title_Line')

            //popup close btn
            const btnPopupCLose=objPopup.btnPopupClose
            this.ui.btnListWithImgs(btnPopupCLose,popup)

            //popup content
            this.ui.createElement('id','div','popup_Content',popup,'popup_Content')
            const popupContent=document.querySelector('#popup_Content')
            const contentCount=objPopup.popupConent
            contentCount.forEach(element => {
                const content=document.createElement('img','content')
                popupContent.append(content)
                content.setAttribute('src',element)
            })
            const btnClosePopup=document.querySelector(`#${objPopup.btnPopupClose[0].btnId}`)
            btnClosePopup.addEventListener('click',()=>{
                popup.remove()
                btnClosePopup.remove()
            })

        })
    }
    generateHelpPopup(btn,objPopup){
        btn.addEventListener('click',()=>{
            
            // popup container
            this.ui.createElement('id','div','popup_container',this.body,'popup')
            const popup=document.querySelector('#popup_container')

            // popup title container
            this.ui.createElement('id','div','popup_title',popup,'popup_title')
            const popupTitleContainer=document.querySelector('#popup_title')

            //popup title
            this.ui.createElement('id','h1','popup_title_text',popupTitleContainer,'popup_title_text')
            const popupTitleText=document.querySelector('#popup_title_text')
            popupTitleText.innerText=`${objPopup.btnText}`

            //popup underline
            this.ui.createElement('id','div','popup_title_Line',popupTitleContainer,'popup_title_Line')
            const popupTitleLine=document.querySelector('#popup_title_Line')

            //popup close btn
            const btnPopupCLose=objPopup.btnPopupClose
            this.ui.btnListWithImgs(btnPopupCLose,popup)

            //popup content
            this.ui.createElement('id','div','popup_Content',popup,'popup_Content')
            const popupContent=document.querySelector('#popup_Content')
            const contentCount=objPopup.popupConent
            contentCount.forEach(element => {
                const content=document.createElement('img','content')
                popupContent.append(content)
                content.setAttribute('src',element)
            })
            const btnClosePopup=document.querySelector(`#${objPopup.btnPopupClose[0].btnId}`)
            btnClosePopup.addEventListener('click',()=>{
                popup.remove()
                btnClosePopup.remove()
            })

        })
    }
    generatePopupVideo(btn,objPopup){
        btn.addEventListener('click',()=>{
            //const btnBusinesCard=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[0].btnId}`)
            this.ui.createElement('id','div','popup_container',this.body,'popup')
            const popup=document.querySelector('#popup_container')
    
            // popup title container
            this.ui.createElement('id','div','popup_title',popup,'popup_title')
            const popupTitleContainer=document.querySelector('#popup_title')
    
            //popup title
            this.ui.createElement('id','h1','popup_title_text',popupTitleContainer,'popup_title_text')
            const popupTitleText=document.querySelector('#popup_title_text')
            popupTitleText.innerText=`${objPopup.btnText}`
    
            //popup underline
            this.ui.createElement('id','div','popup_title_Line',popupTitleContainer,'popup_title_Line')
            const popupTitleLine=document.querySelector('#popup_title_Line')
    
            //popup close btn
            const btnPopupCLose=objPopup.btnPopupClose
            this.ui.btnListWithImgs(btnPopupCLose,popup)
    
            //popup Video content
            this.ui.createElement('id','div','popup_Content',popup,'popup_Content')
            const popupContent=document.querySelector('#popup_Content')
            const contentCount=objPopup.popupConent
            //videocontainer
            const contentVideoContainer=document.createElement('div')
            contentVideoContainer.setAttribute('id','videoContainer')
            //no video image
            const noVideoImg=document.createElement('img')
            noVideoImg.setAttribute('class','noVideo')
            noVideoImg.setAttribute('src','./images/coming-soon.png')
            //video
            const contentVideo=document.createElement('video')
            contentVideo.setAttribute('id','video')
            contentVideo.setAttribute('class','video')
            contentVideo.setAttribute('src',`.${contentCount.videoPath}${contentCount.videoName}${contentCount.type}`)
            contentVideo.setAttribute('type',`video/${contentCount.type}`)
            // contentVideo.setAttribute('autoplay','true')
            //video loader div
            const loadingSpiner=document.createElement('div')
            loadingSpiner.setAttribute('id','videoLoader')
            //video loader img
            const loadingImg=document.createElement('img')
            loadingImg.setAttribute('class','videoLoadImg')
            loadingImg.setAttribute('src','./images/loader_spiner_bg.png')
            contentVideoContainer.append(contentVideo,loadingSpiner,noVideoImg)
            //play controls wrapper
            const playControls=document.createElement('div')
            playControls.setAttribute('id','playControlsWrapper')
            popupContent.append(playControls,contentVideoContainer)
            loadingSpiner.append(loadingImg)
            //play controls
            // playbtn
            const btnPlay=document.createElement('div')
            btnPlay.setAttribute('id','play')
            btnPlay.setAttribute('class','playBtns')
            //playbtn img
            const imgPlay=document.createElement('img')
            imgPlay.setAttribute('class','playBtns')
            imgPlay.setAttribute('src',`./images/${contentCount.playBtn[0]}`)
            btnPlay.append(imgPlay)
            // pausebtn
            const btnPause=document.createElement('div')
            btnPause.setAttribute('id','pause')
            btnPause.setAttribute('class','playBtns')
            //playbtn img
            const imgPause=document.createElement('img')
            imgPause.setAttribute('class','playBtns')
            imgPause.setAttribute('src',`./images/${contentCount.pauseBtn[0]}`)
            btnPause.append(imgPause)
    
            playControls.append(btnPlay,btnPause)
            // img btns swaping
            imgPlay.onmouseenter=()=>{
                // console.log('mouse entred')
                imgPlay.setAttribute('src',`./images/${contentCount.playBtn[1]}`)
            }
            imgPlay.onmouseleave=()=>{
                // console.log('mouse left')
                imgPlay.setAttribute('src',`./images/${contentCount.playBtn[0]}`)
            }
            // img btns swaping
            imgPause.onmouseenter=()=>{
                // console.log('mouse entred')
                imgPause.setAttribute('src',`./images/${contentCount.pauseBtn[1]}`)
            }
            imgPause.onmouseleave=()=>{
                // console.log('mouse left')
                imgPause.setAttribute('src',`./images/${contentCount.pauseBtn[0]}`)
            }
    
            loadingImg.style.display='none'
            console.log(contentVideo)
            if(objPopup.videoPresent){
                contentVideo.setAttribute('autoplay','true')
                const videoPlaying=document.querySelector('#video')
                // console.log(videoPlaying)
                noVideoImg.style.display='none'
                loadingSpiner.style.display='none'
                if(videoPlaying.onwaiting==true&&videoPlaying.waiting==true){
                    console.log('playing')
                    loadingSpiner.style.display='none'
                }
                btnPlay.addEventListener('click',()=>{
                    videoPlaying.play()
                    imgPlay.setAttribute('src',`./images/${contentCount.playBtn[1]}`)
                    btnPause.setAttribute('src',`./images/${contentCount.playBtn[0]}`)
                    loadingSpiner.style.display='none'
                })
                btnPause.addEventListener('click',()=>{
                    videoPlaying.pause()
                    btnPause.setAttribute('src',`./images/${contentCount.playBtn[1]}`)
                    imgPlay.setAttribute('src',`./images/${contentCount.playBtn[0]}`)
                })
            }else{
                noVideoImg.style.display='inline'
            }
            const btnClosePopup=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[3].btnPopupClose[0].btnId}`)
            btnClosePopup.addEventListener('click',()=>{
                popup.remove()
                btnClosePopup.remove()
            })
        })
    }
    generatePopupPDF(btn,objPopup){
        // console.log('pdf popup',objPopup)
        btn.addEventListener('click',()=>{
            //const btnBusinesCard=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[0].btnId}`)
            this.ui.createElement('id','div','popup_container',this.body,'popup')
            const popup=document.querySelector('#popup_container')
    
            // popup title container
            this.ui.createElement('id','div','popup_title',popup,'popup_title')
            const popupTitleContainer=document.querySelector('#popup_title')
    
            //popup title
            this.ui.createElement('id','h1','popup_title_text',popupTitleContainer,'popup_title_text')
            const popupTitleText=document.querySelector('#popup_title_text')
            popupTitleText.innerText=`${objPopup.btnText}`
    
            //popup underline
            this.ui.createElement('id','div','popup_title_Line',popupTitleContainer,'popup_title_Line')
            const popupTitleLine=document.querySelector('#popup_title_Line')
    
            //popup close btn
            const btnPopupCLose=objPopup.btnPopupClose
            this.ui.btnListWithImgs(btnPopupCLose,popup)

            //no pdf image
            const noPdfImg=document.createElement('img')
            noPdfImg.setAttribute('class','noVideo')
            noPdfImg.setAttribute('src','./images/coming-soon.png')
    
            //popup pdfcontent
            this.ui.createElement('id','div','popup_Content',popup,'popup_Content')
            const popupContent=document.querySelector('#popup_Content')
            const contentCount=objPopup.popupConent
            popupContent.append(noPdfImg)

            console.log(objPopup.pdfPresent)
            if(objPopup.pdfPresent){
                contentCount.forEach(element => {
                    // console.log(element)
                    noPdfImg.style.display='none'
                    //this pdf button conainer container
                    const contentPDFButton=document.createElement('div')
                    contentPDFButton.setAttribute('id',element.btnID)
                    contentPDFButton.setAttribute('class','pdf')
                    popupContent.append(contentPDFButton)
                    //image swap conainer
                    const contentImgSwapContainer=document.createElement('div')
                    contentImgSwapContainer.setAttribute('id',element.id)
                    contentImgSwapContainer.setAttribute('class','icon_container btn_swap')
                    contentPDFButton.append(contentImgSwapContainer)
                    //pdf button img
                    const imgPDFBtnIcon=document.createElement('img')
                    imgPDFBtnIcon.setAttribute('src',`./images/${element.btnImgs[0]}`)
                    imgPDFBtnIcon.setAttribute('class','pdfIcon')
                    contentImgSwapContainer.append(imgPDFBtnIcon)
                    //pdf link
                    const pDFLink=document.createElement('a')
                    // pDFLink.setAttribute('href',element.path)
                    pDFLink.setAttribute('class','text')
                    pDFLink.setAttribute('onclick',`window.open('./textures/${element.name})'`)
                    pDFLink.innerText=element.name
                    contentPDFButton.append(pDFLink)
                    // image swapping
                    // image swapping
                    contentPDFButton.onmouseenter=()=>{
                        // console.log('mouse entred')
                        imgPDFBtnIcon.setAttribute('src',`./images/${element.btnImgs[1]}`)
                    }
                    contentPDFButton.onmouseleave=()=>{
                        // console.log('mouse left')
                        imgPDFBtnIcon.setAttribute('src',`./images/${element.btnImgs[0]}`)
                    }
                    // console.log(btn)
                })
            }else{
                noPdfImg.style.display='inline'
            }
            const btnClosePopup=document.querySelector(`#${this.refArray.btnBoothUIIcons[0].btns[3].btnPopupClose[0].btnId}`)
            btnClosePopup.addEventListener('click',()=>{
                popup.remove()
                btnClosePopup.remove()
            })
        })
    }

}
export{BoothGenerator}