class HallUI{
    constructor(ui,refArray){
        this.refArray=refArray
        this.ui=ui
        this.body=document.querySelector('body')

        if(this.refArray){
            // console.log(this.refArray.btnBoothUIIcons)
            /** */
            //create hall nav buttons
            const btnUI=this.refArray.btnUIIcons
            this.ui.btnListWithImgs(btnUI,this.body)
            /** */
        }
        // console.log(this.ui.body)
    }
    generateBooth(btn,objBooth){
        btn.addEventListener('click',()=>{
            console.log(objBooth)
            this.ui.createBooth(objBooth)

            //create back button
            const backBtnForBooth=this.refArray.btnBoothBack
            this.ui.btnListWithImgs(backBtnForBooth,this.body)

            //add back button functionality
            const btnBackBooth=document.querySelector(`#${this.refArray.btnBoothBack[0].btnId}`)
            btnBackBooth.addEventListener('click',()=>{
                console.log('back btn')
                const boothContainer=document.querySelector(`#booth_container`)
                boothContainer.remove()
                btnBoothBack.remove()
            })
        })
    }
}
export{HallUI}