import {createScene} from "./components/scene.js"
import {Camera} from "./components/Camera.js"
import {createRenderer} from "./components/Renderer.js"
import { Lights } from "./components/Lights.js"

import { Resize } from "./systems/Resize.js"
import { Loaders } from "./systems/Loaders.js"
import { Controls } from "./systems/ControlsOrbit.js"
import { Ticker } from "./systems/Ticker.js"
import { EnviLighting } from "./systems/EnviLight.js"
import { Statistics } from "./systems/Stats.js"
import { GltfManager } from "./systems/GltfManager.js"
import { UIElements } from "./systems/UIObject.js"
import { ExtFilesArray } from "./components/ExternalFiles.js"
import { RaycastingManager } from "./systems/Raycaster.js"
import { BoothPopUps } from "./systems/ManagerBoothPopups.js"
import { BoothGenerator } from "./systems/ManagerBoothGenerator"
import { HallUI } from "./systems/ManagerHallUI.js"

let renderer,scene,camera
let resizer,loader,controls,tick,objectsTick
let refArray,ui,raycaster,boothGenerator,boothPopups,hallUi
class World{
    constructor(container){
        this.container=container
        refArray=new ExtFilesArray()
        ui=new UIElements(refArray)
        hallUi=new HallUI(ui,refArray)
        boothGenerator=new BoothGenerator(ui,refArray)
        // boothPopups=new BoothPopUps(ui,refArray)
        
        scene=createScene()
        camera=new Camera(container,scene)
        renderer=createRenderer()
        container.append(renderer.domElement)
        
        loader=new Loaders(camera,renderer,refArray)
        controls=new Controls(loader)
        raycaster=new RaycastingManager(refArray,camera,container,scene)
        
        const enviLighting=new EnviLighting(scene,loader,refArray)
        const lighting=new Lights(scene)
        
        objectsTick=[]
        objectsTick.push(controls,raycaster)
        
        resizer=new Resize(container,renderer,scene,camera)
        window.addEventListener('resize',()=>resizer.onResize())
        
        tick=new Ticker(renderer,resizer,objectsTick)       
        
        // console.log(refArray)
    }
    async init(){
        const gltfManager=new GltfManager(ui,loader,scene,camera,refArray,controls)
    }
    render(){
        resizer.render()
    }
}
export {World}