import { Fullscreen } from "./Fullscreen.js"
import {BoothPopUps } from "./ManagerBoothPopups.js"

class UIElements{
    constructor(refArray){
        this.fullsrn=new Fullscreen()
        this.refArray=refArray
        this.boothPopUps=new BoothPopUps(refArray)
        
        this.body=document.querySelector('body')

        // console.log(this.refArray)
        
        /** 
         * LANDING PAGE
        */
        //landing Page
        this.createElement('id','div','landing_Page',this.body,'container')
        const landingPageContainer=document.querySelector('#landing_Page')

        //landing page image
        this.createImg('loaderImgText','landing_page_img',landingPageContainer,'loader_page_text.png')
        
        //landing page image
        this.createImg('btnShiftImg1','landing_page_img',landingPageContainer,this.refArray.landPageElements.img)

        //landing Page
        this.createElement('id','div','spinerContainer',this.body,'spinerContainer')
        const spinereContainer=document.querySelector('#spinerContainer')

        //landing spiner page image
        this.createImg('loaderLogo','loaderLogo',spinereContainer,'loader_spiner_bg.png')

        //landing spiner page image
        this.createImg('loaderRotation','spiner loaderLogo',spinereContainer,'rotating_btn_element.png')


        /** 
         * WELCOME PAGE
        *
        //Welcome Page
        this.createElement('id','div','welcome_Page',this.body,'container')
        const welcomePageContainer=document.querySelector('#welcome_Page')

        //welcome page image
        // this.createImg('btnShiftImg2','landing_page_img',welcomePageContainer,this.refArray.landPageElements.img)

        //welcome icon btc image
        // this.createImg('loader_btc_image','loader_btc_image',welcomePageContainer,this.refArray.landPageElements.img)

        //welcome icon btc image
        // this.createImg('loader_page_text','landing_page_img',welcomePageContainer,this.refArray.landPageElements.img)
        
        /** 
         * TOP BAR
        */
        //Top Bar
        this.createElement('id','div','topNav',this.body,'nav')
        const topNavContainer=document.querySelector('#topNav')
        //top bar logo img
        this.createImg('topBarLogoImg','img_top_bar',topNavContainer,this.refArray.topBar.topBarLogo)
        //top bar back img
        this.createImg('topBarBackImg','img_top_bar',topNavContainer,this.refArray.topBar.topBackgrdImg)
        //top bar shadow img
        this.createImg('topBarShadowImg','img_top_bar',topNavContainer,this.refArray.topBar.topBarShadow)
        //top bar btc logo
        this.createImg('topBTCLogo','img_top_bar',topNavContainer,this.refArray.topBar.topBTCLogo)

        //back button
        /** */
        // comapny button
        const backBtnList=this.refArray.btnBack
        this.btnListWithImgs(backBtnList,topNavContainer)

        /**
         * BOTTOM BAR
         */
        //bottom Bar
        this.createElement('id','div','bottomNav',this.body,'nav')
        const bottomNavContainer=document.querySelector('#bottomNav')
        const btnSwapImgList=this.refArray.btnSwap

        //company list
        const companyBtnContainer=document.createElement('ul','companyBtnContainer')
        bottomNavContainer.appendChild(companyBtnContainer)
        companyBtnContainer.setAttribute('id','comapanyBtnWrapper')
        companyBtnContainer.setAttribute('class','wrapper')

        /** */
        // comapny button
        const companyBtnList=this.refArray.companyBtns
        this.btnListWithImgs(companyBtnList,companyBtnContainer)

        /** */
        //help button
        const bottomBarRight=this.refArray.btnsHelp
        this.btnListWithImgs(bottomBarRight,companyBtnContainer)

        /** */
        // console.log(this.refArray.btnBoothUIIcons)
    }

    openURL(btn,url){
        btn.addEventListener('click',()=>{
            window.open(url)
        })
    }
    createElement(tagName,elementTag,elementIdName,appendElement,className){
        const element=document.createElement(elementTag)
        element.setAttribute(tagName,elementIdName)
        element.setAttribute('class',className)
        appendElement.appendChild(element)
    }
    createImg(imgName,className,imgContainer,imgFileName){
        //landing page image
        this.createElement('id','img',`${imgName}`,imgContainer,className)
        const img=document.querySelector(`#${imgName}`)
        img.setAttribute('src',`./images/${imgFileName}`)
    }
    btnImgSwap(imageName){
        // console.log(imageName)
        imageName.forEach(element => {
            console.log(element)
            // element.innerHTML=`<img src='./images/${btnObjList.btnImgs[1]}' class=${btnObjList.class}>`
            const btn=document.querySelector(`#${element.Id}`)
            console.log(btn)
            element.onmouseenter=()=>{
                console.log('mouse over swap')
                element.setAttribute('src',`./images/${element.btnImgs[1]}`)
            }
            element.onmouseleave=()=>{
                console.log('mouse over swap')
                // btn.setAttribute('src',`./images/${element.btnImgs[0]}`)
            }
            // console.log(btn,element.btnImgs)
        })
    }
    btnListWithImgs(btnObjList,wrapper){
        btnObjList.forEach(element => {
            // console.log(element)
            const companyBtn=document.createElement(`${element.elementTag}`,`${element.btnId}`)
            wrapper.appendChild(companyBtn)
            companyBtn.setAttribute('id',`${element.btnId}`)
            companyBtn.setAttribute('class',`btn_swap ${element.class}`)
            const btnImg=document.createElement('img',`${element.btnId}`)
            companyBtn.appendChild(btnImg)
            btnImg.setAttribute('src',`./images/${element.btnImgs[0]}`)
            companyBtn.style.display=`${element.display}`
            // console.log(element)
            this.btnListSwapImgs(element)
            // const companyspiner=document.createElement(`${element.elementTag}`,`${element.btnId}`)
        })
    }
    btnListWithImgsText(btnObjList,wrapper){
        btnObjList.forEach(element => {
            // console.log(element)
            const companyBtn=document.createElement(`${element.elementTag}`,`${element.btnId}`)
            wrapper.appendChild(companyBtn)
            companyBtn.setAttribute('id',`${element.btnId}`)
            companyBtn.setAttribute('class',`btn_swap ${element.class}`)
            const btnImg=document.createElement('img',`${element.btnId}`)
            companyBtn.appendChild(btnImg)
            btnImg.setAttribute('src',`./images/${element.btnImgs[0]}`)
            companyBtn.style.display=`${element.display}`
            // console.log(element)
            this.btnListSwapImgs(element)
            // const companyspiner=document.createElement(`${element.elementTag}`,`${element.btnId}`)
        })
    }
    btnListSwapImgs(obj){
        const btn=document.querySelector(`#${obj.btnId}`)
        // console.log(btn.children[0])
        btn.children[0].onmouseenter=()=>{
            // console.log('mouse entred')
            btn.children[0].setAttribute('src',`./images/${obj.btnImgs[1]}`)
        }
        btn.children[0].onmouseleave=()=>{
            // console.log('mouse left')
            btn.children[0].setAttribute('src',`./images/${obj.btnImgs[0]}`)
        }
    }

    createBtnBoothNavigation(btnObjList,wrapper){
        btnObjList.forEach(element => {
            // console.log(element)
            const boothBtn=document.createElement(`${element.elementTag}`,`${element.btnId}`)
            wrapper.appendChild(boothBtn)
            boothBtn.setAttribute('id',`${element.btnId}`)
            boothBtn.setAttribute('class',`btn_swap ${element.class}`)
            const btnImg=document.createElement('img',`${element.btnId}`)
            boothBtn.appendChild(btnImg)
            btnImg.setAttribute('src',`./images/${element.btnImgs[0]}`)
            boothBtn.style.display=`${element.display}`
            // console.log(element)
            this.btnListSwapImgs(element)
            // const companyspiner=document.createElement(`${element.elementTag}`,`${element.btnId}`)
        })
    }
    createBtnBoothNavigationText(btnObjList,wrapper){
        btnObjList.forEach(element => {
            // console.log(element)

            //create btn
            const boothBtn=document.createElement(`${element.elementTag}`,`${element.btnId}`)
            wrapper.appendChild(boothBtn)
            boothBtn.setAttribute('id',`${element.btnId}`)
            boothBtn.setAttribute('class',`btn_swap ${element.class}`)

            
            //image attach code
            const btnImg=document.createElement('img',`${element.btnId}`)
            boothBtn.appendChild(btnImg)
            btnImg.setAttribute('src',`./images/${element.btnImgs[0]}`)
            boothBtn.style.display=`${element.display}`
            
            //btn text
            const boothBtnText=document.createElement(`${element.elementTag}`,`${element.btnId}`)
            boothBtn.appendChild(boothBtnText)
            boothBtnText.setAttribute('id',`${element.btnId}`)
            boothBtnText.setAttribute('class',`boothToGenerateText`)
            boothBtnText.innerText=element.btnId

            // console.log(boothBtn)

            //images swap code
            this.btnListSwapImgs(element)
            // const companyspiner=document.createElement(`${element.elementTag}`,`${element.btnId}`)
        })
    }
    createBooth(objList){
        // console.log(objList.btnId)
        this.createElement('id','div',`${objList.boothName}`,this.body,'booth')
        const boothconatiner=document.querySelector(`#${objList.boothName}`)
        // console.log(boothconatiner)

        this.createElement('id','img','booth_bkgrd_img',boothconatiner,'booth booth_bkgrd_img')
        const boothBbkgrdImg=document.querySelector('#booth_bkgrd_img')
        boothBbkgrdImg.setAttribute('src',`${objList.boothIamage
        }`)

        this.createElement('id','div','booth_bottom_bar',boothconatiner,'booth_bottom_bar')
        const boothBootBar=document.querySelector('#booth_bottom_bar')

        this.createElement('id','div','booth_btns_wrapper',boothBootBar,'booth_btns_wrapper')
        const boothBtnWrapper=document.querySelector('#booth_btns_wrapper')

         
        //booth buttons
        /** */
        objList.btns.forEach(element => {
            // console.log(element)
            //booth btn text
            //booth btns
            const companyBtn=document.createElement(`div`,`${element.btnId}`)
            boothBtnWrapper.appendChild(companyBtn)
            companyBtn.setAttribute('id',`${element.btnId}`)
            companyBtn.setAttribute('class',`btn_swap ${element.class}`)
            //booth btn imgs
            const btnImg=document.createElement('img',`${element.btnId}`)
            companyBtn.appendChild(btnImg)
            btnImg.setAttribute('src',`./images/${element.btnImgs[0]}`)
            const btnImgSpinner=document.createElement('img',`spinner`)
            //booth btn text
            const companyBtnText=document.createElement(`div`,`${element.btnId}Text`)
            companyBtn.appendChild(companyBtnText)
            companyBtnText.setAttribute('id',`${element.btnId}`)
            companyBtnText.setAttribute('class',`boothbtnsText`)
            companyBtnText.innerText=`${element.btnId}`    
            //booth btn spiner
            companyBtn.appendChild(btnImgSpinner)
            btnImgSpinner.setAttribute('src',`${element.spineIcon}`)
            btnImgSpinner.setAttribute('class',`spiner`)
            companyBtn.style.display=`${element.display}`
            // console.log(element)
            this.btnListSwapImgs(element)
        })
    }
}
export{UIElements}
